// import * as React from 'react';
import { useEffect } from 'react';
import { inject } from 'mobx-react';
import {
  setVacFrameVisibilityHidden,
  VirtualAgentControl,
} from '@shared-ui/customer-virtual-agent-control';
import { Viewport, ViewSmall, ViewMedium } from '@shared-ui/viewport-context';
import {
  renderVacProps,
  VirtualAgentControlProps,
  floatingVacProps,
  embeddedVacProps,
  VirtualAgentControlAdapter,
  VirtualAgentControl as VirtualAgentControlType,
} from './virtual-agent-control-types';
import { VacConfig, fallbackError, vacEvents, egClickStreamConstants } from 'common/constant';
import { EVENTS } from 'utils/logger-events';
import { prepareClickStreamPayload } from 'common/helper';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { useExperiment } from '@shared-ui/experiment-context';
import { useResourceContext } from 'rm-plugin';
import { ClientLogger } from 'bernie-client';
import loggingConstants from 'common/loggingConstants';
import { useVirtualAgentContext, REQUIRED_EVENT } from '@shared-ui/retail-help-center-util';
import React from 'react';

const { errorEvents, vacHandledErrorEvents, errorEventMap } = VacConfig;
const { vacConversationCreatedEvent } = vacEvents;

const logger = ClientLogger.getLoggerWithIdentifier('virtual-agent-control');

declare const window: Window & {
  VirtualAgentControl: VirtualAgentControlType;
  VacClientAdapter: VirtualAgentControlAdapter;
};
interface Props {
  blankSlateLayout?: boolean;
  userHasTrip?: boolean;
}

export const RenderVac: React.FC<renderVacProps & Props> = inject('virtualAgentControlStore')(
  ({ config, virtualAgentProps, virtualAgentControlStore, blankSlateLayout, userHasTrip }) => {
    const trackClick = useEgClickstream();
    const { exposure, logExperiment } = useExperiment('Help_Center_AA_Match_Rate');
    const { egClickStreamEventNames, egClickStreamEventTypes, egClickStreamEventCategories } = egClickStreamConstants;
    const subscribedEvents = Object.values(vacEvents);
    const { resourceContext } = useResourceContext();
    const chatBackData = resourceContext['quickLinks']['chatBackData'];
    const chatBackDataExpired = !chatBackData?.expiryUTCTime ? true : new Date() > new Date(chatBackData.expiryUTCTime);
    const intentMessage = resourceContext.intentParams || undefined;
    const { config: vacConfig } = useVirtualAgentContext();

    const trackVacLoadEvent = () => {
      const clickStreamEvent = prepareClickStreamPayload(
        virtualAgentControlStore,
        egClickStreamEventNames.virtualAgentInitiated,
        egClickStreamEventTypes.impression,
        egClickStreamEventCategories.virtualAgent
      );
      trackClick(clickStreamEvent);
    };

    const trackVacLoadFailEvent = () => {
      const clickStreamEvent = prepareClickStreamPayload(
        virtualAgentControlStore,
        egClickStreamEventNames.virtualAgentFailed,
        egClickStreamEventTypes.outcome,
        egClickStreamEventCategories.virtualAgent
      );
      trackClick(clickStreamEvent);
    };
    const handleVacEvents = (event, props) => {
      if (
        chatBackDataExpired &&
        !intentMessage?.intent &&
        event.type === vacEvents.vacFabPresented &&
        blankSlateLayout &&
        userHasTrip
      ) {
        setVacFrameVisibilityHidden(true);
      }

      if (event.type === vacConversationCreatedEvent) {
        const { conversationId, participantId } = event.payload;
        virtualAgentControlStore.setVirtualAgentControlIdentifiers(conversationId, participantId);
        trackVacLoadEvent();
      }

      if (event.type === vacEvents.vacInitializedEvent) {
        logExperiment(exposure);
      }
      // Using indexOf instead of includes for IE support.
      if (config?.successEvents.indexOf(event.type) !== -1) {
        logger.logEvent(EVENTS.VAC_SUCCESS_EVENT_RECEIVED, {
          message: `Success event received from VAC - ${event.type}`,
        });
        props.removeLoader();
      } else if (errorEvents.indexOf(event.type) !== -1) {
        const errorType = errorEventMap[event.type] || fallbackError;
        props.setError(errorType);
        logger.logEvent(EVENTS.VAC_ERROR_EVENT_RECEIVED, {
          message: `${loggingConstants.VAC_ERROR_EVENT_RECEIVED}: Error Event received from VAC - ${event.type}`,
        });
        trackVacLoadFailEvent();
      } else if (vacHandledErrorEvents.indexOf(event.type) != -1) {
        // For such VAC handled error events we don't need to show our custom error UI as VAC already has handling for these.
        props.removeLoader();

        logger.logEvent(EVENTS.VAC_ERROR_EVENT_RECEIVED, {
          message: `${loggingConstants.VAC_ERROR_EVENT_RECEIVED}: Error Event received from VAC - ${event.type}`,
        });
      } else {
        // This logs any non success/error events received if we are subscribed to them
        logger.logEvent(EVENTS.VAC_EVENT_RECEIVED, { message: `VAC event received - ${event.type}` });
      }
      return;
    };

    useEffect(() => {
      const timer = setTimeout(() => {
        if (window.VirtualAgentControl?.initialized && window.VacClientAdapter) {
          virtualAgentProps.removeLoader();
        }
      }, 3000);
      return () => clearTimeout(timer);
    }, []);

    return (
      <>
        {chatBackDataExpired ? (
          <VirtualAgentControl
            applicationName="ecp-help-center"
            pageName="ecp-help-center"
            clientOverrides={{
              subscribedEvents: [...subscribedEvents, ...REQUIRED_EVENT],
              eventHandler: (event) => {
                handleVacEvents(event, virtualAgentProps);
                vacConfig.eventHandler(event)
              },
              enableAutoOpenChatWidget: !!intentMessage,
              embedded: config.embedded,
              containerElement: config.embedded ? document.getElementById('vac-container') : undefined,
              configurationOverrides: {
                hideHeader: config.hideHeader,
                hideAvatar: config.hideAvatar,
                textInputPlaceHolder: config.textInputPlaceHolder,
              },
              intentMessage: intentMessage,
            }}
            inputs={{}}
            skipSsr
          />
        ) : (
          <VirtualAgentControl
            applicationName="ecp-help-center"
            pageName="ecp-help-center"
            clientOverrides={{
              subscribedEvents: [...subscribedEvents, ...REQUIRED_EVENT],
              eventHandler: (event) => {
                handleVacEvents(event, virtualAgentProps);
                vacConfig.eventHandler(event)
              },
              enableAutoOpenChatWidget: true,
              embedded: config.embedded,
              containerElement: config.embedded ? document.getElementById('vac-container') : undefined,
              configurationOverrides: {
                hideHeader: config.hideHeader,
                hideAvatar: config.hideAvatar,
                textInputPlaceHolder: config.textInputPlaceHolder,
              },
              intentMessage: {
                intent: chatBackData.intent,
                quickLinkId: chatBackData.quickLinkId,
                ...chatBackData.payload,
              },
              mode: 'JOIN_CONVERSATION',
              conversationId: chatBackData.conversationId,
              participantId: chatBackData.participantId,
            }}
            inputs={{}}
            skipSsr
          />
        )}
      </>
    );
  }
);

export const VirtualAgentControlComponent: React.FC<VirtualAgentControlProps> = (props: VirtualAgentControlProps) => (
  <Viewport>
    <ViewSmall>
      <RenderVac config={floatingVacProps} virtualAgentProps={props} />
    </ViewSmall>
    <ViewMedium>
      <RenderVac config={embeddedVacProps} virtualAgentProps={props} />
    </ViewMedium>
  </Viewport>
);

VirtualAgentControlComponent.displayName = 'VirtualAgentControlComponent';
