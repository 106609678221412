import * as React from 'react';
import { UitkText } from 'uitk-react-text';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLink } from 'uitk-react-link';
import { VacConfig } from 'common/constant';
export interface ErrorComponentProps {
  error: string;
  reloadVac: () => void;
}

export const VacErrorComponent: React.FC<ErrorComponentProps> = ({error, reloadVac}: ErrorComponentProps) => {
  const errorConfig = VacConfig.errorConfig;
  const errorData = errorConfig[error];
  return (
    error && errorData ?
      <div className={'vacLoader'}>
        <UitkIcon name={errorData.iconName} size={ UitkIconSize.XLARGE }/>
       
          <UitkText align="center" className="vacError-header" > {errorData.errorHeader} </UitkText>
          
            <UitkText align="center" className="vacError-subheader"> {errorData.errorSubheader} </UitkText>
          
        {errorData.refreshButton?.enabled ?
        <UitkLink align="center" onClick={reloadVac}>
          <UitkText>{errorData.refreshButton?.buttonText}</UitkText>
        </UitkLink> : null}
      </div> : null
  );
};

VacErrorComponent.displayName = 'VacErrorComponent';
