import { VirtualAgentControlAdapter, VirtualAgentControl, message } from './virtual-agent-control-types';
import { ClientLogger } from 'bernie-client';
import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import loggingConstants from '../../common/loggingConstants';

declare const window: Window & {
  VirtualAgentControl: VirtualAgentControl;
  VacClientAdapter: VirtualAgentControlAdapter;
};

const logger = ClientLogger.getLoggerWithIdentifier('VACHelpers');

export async function sendMessage(data: message): Promise<void> {
  if (window?.VirtualAgentControl?.initialized && window?.VacClientAdapter) {
    const isInit = await window.VirtualAgentControl.initialized;
    if (isInit) {
      const chatInstance = await window.VacClientAdapter.getInstance();
      if (await chatInstance.isInitialized) {
        logger.logEvent(new SystemEvent(SystemEventLevel.INFO, loggingConstants.SENDING_MESSAGE), {
          message: `Sending message - ${data.text}`,
        });
        chatInstance?.vacFrame?.sendMessage(data);
        return;
      }
    }
  }
  logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, loggingConstants.VAC_NOT_INITIALISED), {
    message: `${loggingConstants.VAC_ERROR_EVENT_RECEIVED}: VAC is not initialised when trying to send message - ${data.text}`,
  });
}
