import React, { useState } from 'react';
import { useResourceContext, IResourceContextData } from 'rm-plugin';
import { VirtualAgentControl, VirtualAgentControlClientConfig } from '@shared-ui/customer-virtual-agent-control';
import { useLocation } from 'react-router-dom-v5-compat';
import { vacEvents } from 'common/constant';
import { sendMessage } from './virtual-agent-control-helpers';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkLoader } from 'uitk-react-loader';

type StandAloneVaProps = {
  container: HTMLElement;
};

type StandAloneVAUrlParam = Pick<
  VirtualAgentControlClientConfig,
  'intentMessage' | 'conversationId' | 'participantId' | 'mode'
>;

const createContextualMessageConfig: (resource: IResourceContextData) => VirtualAgentControlClientConfig = (
  resource: IResourceContextData
) => {
  const {
    intentParams: intentMessage,
    quickLinks: { chatBackData },
  } = resource.resourceContext;
  const chatBackDataExpired = !chatBackData?.expiryUTCTime ? true : new Date() > new Date(chatBackData.expiryUTCTime);
  if (!chatBackDataExpired) {
    return {
      intentMessage: {
        intent: chatBackData.intent,
        quickLinkId: chatBackData.quickLinkId,
        ...chatBackData.payload,
      },
      mode: 'JOIN_CONVERSATION',
      conversationId: chatBackData.conversationId,
      participantId: chatBackData.participantId,
    };
  }

  if (intentMessage && Object.keys(intentMessage).length > 0) {
    return {
      intentMessage: intentMessage,
    };
  }

  return null;
};
export const StandAloneVa: React.FC<StandAloneVaProps> = ({ container }: StandAloneVaProps) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  const queryParams: StandAloneVAUrlParam = {
    intentMessage: JSON.parse(searchParams.get('intentMessage')) || undefined,
  };
  const resource = useResourceContext();
  const contextualChatbackData = createContextualMessageConfig(resource);

  const vacEventHandler = (event) => {
    if (event.type === vacEvents.vacConversationCreatedEvent) {
      setIsLoading(false);
      queryParams.intentMessage && sendMessage(queryParams.intentMessage);
    }
  };

  return (
    <>
      <VirtualAgentControl
        applicationName="ecp-help-center"
        pageName="ecp-help-center"
        clientOverrides={{
          ...contextualChatbackData,
          subscribedEvents: [vacEvents.vacConversationCreatedEvent],
          eventHandler: vacEventHandler,
          enableProactiveConversation: true,
          enableAutoOpenChatWidget: true,
          embedded: true,
          containerElement: container,
        }}
        inputs={{}}
      />
      {isLoading && (
        <UitkLayoutFlex id={'stand-alone-vac-loader'} alignItems={'center'} justifyContent={'center'}>
          <UitkLoader />
        </UitkLayoutFlex>
      )}
    </>
  );
};
