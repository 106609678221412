import { egClickStreamConstants } from 'common/constant';
import { EgClickstreamData } from '@shared-ui/clickstream-analytics-context';
import {
  ClickstreamAnalyticsEventFragment,
  ClickstreamAnalyticsExperienceFragment,
  TripInfoDetailFields,
} from './__generated__/api/types';
import BookingActionsClickStreamAnalytics = TripInfoDetailFields.bookingActionsClickStreamAnalytics

export function prepareClickStreamPayload(
  virtualAgentControlStore,
  eventName,
  eventType,
  eventCategory,
  actionLocation = undefined,
  egClickStreamEventWorkFlowItems = undefined,
  intentButtonName = undefined
) {
  const {
    egClickStreamEventVersion,
    egClickStreamEventPageName,
    egClickStreamEventPageNameDetailed,
    egClickStreamEventAppName,
  } = egClickStreamConstants;
  const clickStreamEvent = {
    event: {
      event_version: egClickStreamEventVersion,
      event_name: eventName,
      event_type: eventType,
      event_category: eventCategory,
      ...(actionLocation ? { action_location: actionLocation } : {}),
    },
    virtual_agent: {
      conversation_id: virtualAgentControlStore?.conversationId,
      participant_id: virtualAgentControlStore?.participantId,
    },
    experience: {
      page_name: egClickStreamEventPageName,
      page_name_detailed: egClickStreamEventPageNameDetailed,
      app_name: egClickStreamEventAppName,
      app_version: egClickStreamEventVersion,
    },
  };
  if (egClickStreamEventWorkFlowItems) {
    clickStreamEvent['work_flow_list'] = egClickStreamEventWorkFlowItems;
  }
  if (intentButtonName) {
    clickStreamEvent['additional_context'] = buildIntentObject(intentButtonName);
  }
  return clickStreamEvent;
}

export const buildClickStreamAnalyticsForContactUsSelectedEvent = (): EgClickstreamData => {
  return {
    event: {
      event_name: egClickStreamConstants.egClickStreamEventNames.helpCenterContactUsSelected,
      event_version: egClickStreamConstants.egClickStreamEventVersion,
      event_type: egClickStreamConstants.egClickStreamEventTypes.interaction,
      event_category: egClickStreamConstants.egClickStreamEventCategories.helpCenter,
      action_location: egClickStreamConstants.egClickStreamLocation,
    },
    experience: {
      page_name: egClickStreamConstants.egClickStreamEventPageName,
      app_name: egClickStreamConstants.egClickStreamEventAppName,
      app_version: egClickStreamConstants.egClickStreamEventVersion,
      page_name_detailed: egClickStreamConstants.egClickStreamEventPageNameDetailed,
    },
  };
};

export const buildClickStreamAnalyticsForTaapAcademySelectedEvent = (): EgClickstreamData => {
  return {
    event: {
      event_name: egClickStreamConstants.egClickStreamEventNames.taapAcademyComponentSelected,
      event_version: egClickStreamConstants.egClickStreamEventVersion,
      event_type: egClickStreamConstants.egClickStreamEventTypes.interaction,
      event_category: egClickStreamConstants.egClickStreamEventCategories.helpCenter,
      action_location: egClickStreamConstants.egClickStreamLocation,
    },
    experience: {
      page_name: egClickStreamConstants.egClickStreamEventPageNameDetailed,
      app_name: egClickStreamConstants.egClickStreamEventAppName,
      app_version: egClickStreamConstants.egClickStreamEventVersion,
      page_name_detailed: egClickStreamConstants.egClickStreamEventPageNameDetailed,
    },
  };
};

export const buildClickStreamAnalyticsForSignInCtaActionEvent = (
  eventName: string,
  payload: ClickstreamAnalyticsEventFragment,
  experience: ClickstreamAnalyticsExperienceFragment
): EgClickstreamData => {
  return {
    event: {
      event_name: eventName,
      event_version: payload.eventVersion,
      event_type: payload.eventType,
      event_category: payload.eventCategory,
      action_location: payload.actionLocation,
    },
    experience: {
      page_name: experience.pageName,
      app_name: experience.appName,
      app_version: experience.appVersion,
      page_name_detailed: experience.pageNameDetailed,
    },
  };
};

export const buildClickStreamAnalyticsForTripSelectedEvent = (
  tripName: string,
  numberOfBookings: string
): EgClickstreamData => {
  return {
    event: {
      event_name: egClickStreamConstants.egClickStreamEventNames.helpCenterTripSelected,
      event_version: egClickStreamConstants.egClickStreamEventVersion,
      event_type: egClickStreamConstants.egClickStreamEventTypes.interaction,
      event_category: egClickStreamConstants.egClickStreamEventCategories.helpCenterTrip,
      action_location: egClickStreamConstants.egClickStreamLocation,
    },
    experience: {
      page_name: egClickStreamConstants.egClickStreamEventPageName,
      app_name: egClickStreamConstants.egClickStreamEventAppName,
      app_version: egClickStreamConstants.egClickStreamEventVersion,
      page_name_detailed: egClickStreamConstants.egClickStreamEventPageNameDetailed,
    },
    trip: {
      trip_detail_list: [
        {
          trip_name: tripName,
          trip_item_count: parseInt(numberOfBookings),
        },
      ],
    },
  };
};

export const buildClickStreamAnalyticsForBookingSelectedEvent = (title: string, product: string): EgClickstreamData => {
  return {
    event: {
      event_name: egClickStreamConstants.egClickStreamEventNames.helpCenterTripBookingSelected,
      event_version: egClickStreamConstants.egClickStreamEventVersion,
      event_type: egClickStreamConstants.egClickStreamEventTypes.interaction,
      event_category: egClickStreamConstants.egClickStreamEventCategories.helpCenterTrip,
      action_location: egClickStreamConstants.egClickStreamLocation,
    },
    experience: {
      page_name: egClickStreamConstants.egClickStreamEventPageName,
      app_name: egClickStreamConstants.egClickStreamEventAppName,
      app_version: egClickStreamConstants.egClickStreamEventVersion,
      page_name_detailed: egClickStreamConstants.egClickStreamEventPageNameDetailed,
    },
    product_list: [
      {
        product_line: product,
        product_name: title,
      },
    ],
  };
};

export const buildClickStreamForBookingActions = (
  bookingActionsClickStreamAnalytics: BookingActionsClickStreamAnalytics
): EgClickstreamData => {
  const trip = buildTrip(bookingActionsClickStreamAnalytics);
  const workFlowList = buildWorkFlowList(bookingActionsClickStreamAnalytics);
  return {
    event: {
      event_name: bookingActionsClickStreamAnalytics.event.eventName,
      event_version: bookingActionsClickStreamAnalytics.event.eventVersion,
      event_type: bookingActionsClickStreamAnalytics.event.eventType,
      event_category: bookingActionsClickStreamAnalytics.event.eventCategory,
      ...(bookingActionsClickStreamAnalytics.event.actionLocation && {
        action_location: bookingActionsClickStreamAnalytics.event.actionLocation,
      }),
      ...(bookingActionsClickStreamAnalytics.event.actionReason && {
        action_reason: bookingActionsClickStreamAnalytics.event.actionReason,
      }),
    },
    experience: {
      page_name: bookingActionsClickStreamAnalytics.experience.pageName,
      app_name: bookingActionsClickStreamAnalytics.experience.appName,
      app_version: bookingActionsClickStreamAnalytics.experience.appVersion,
      page_name_detailed: bookingActionsClickStreamAnalytics.experience.pageNameDetailed,
    },
    ...workFlowList,
    ...trip,
  };
};

const buildTrip = (
  analytics: BookingActionsClickStreamAnalytics
): { trip?: { trip_detail_list: { trip_domain_id: string }[] } } => {
  if (analytics.tripDetail && analytics.tripDetail.length > 0) {
    return {
      trip: {
        trip_detail_list: [
          {
            trip_domain_id: analytics.tripDetail[0].tripDomainId || null,
          },
        ],
      },
    };
  }
  return {};
};

const buildWorkFlowList = (
  analytics: BookingActionsClickStreamAnalytics
): {
  work_flow_list?: {
    work_flow_list: {
      intent: string;
      automation_status: string;
      automation_status_reason: string;
      product_line: string;
      order_line_id: string;
      channel: string;
      sub_intent?: string;
    }[];
  };
} => {
  if (analytics.workFlowList && analytics.workFlowList.length > 0) {
    const firstWorkFlow = analytics.workFlowList[0];
    const workflow = {
      intent: firstWorkFlow.intent,
      automation_status: firstWorkFlow.automationStatus,
      automation_status_reason: firstWorkFlow.automationStatusReason,
      product_line: firstWorkFlow.productLine,
      order_line_id: firstWorkFlow.orderLineId,
      channel: firstWorkFlow.channel,
      ...(firstWorkFlow.subIntent && { sub_intent: firstWorkFlow.subIntent }),
    };

    return {
      work_flow_list: {
        work_flow_list: [workflow],
      },
    };
  }
  return {};
};

export const buildIntentObject = (buttonName: string) => {
  return {
    user_interface: {
      component_element_text: buttonName,
    },
  };
};
