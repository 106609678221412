import { VirtualAgentControlStore } from '../../stores/virtual-agent-control-store';
import { VacConfig } from 'common/constant';

export interface VirtualAgentControl {
  initialized: Promise<boolean>;
}
export interface VacFrame {
  sendMessage: (data) => void;
}

export interface VirtualAgentControlClient {
  openChatWidget: () => void;
}

export interface VirtualAgentControlClientMultiParticipant extends VirtualAgentControlClient {
  isInitialized: Promise<boolean>;
  vacFrame: VacFrame;
}
export interface VirtualAgentControlAdapter {
  getInstance: () => Promise<VirtualAgentControlClientMultiParticipant>;
}

export interface message {
  messageType?: string;
  payload?: string;
  text?: string;
  intent?: string;
}

export interface VirtualAgentControlProps {
  isLoading: boolean;
  removeLoader: () => void;
  setError: (error: string) => void;
}

export interface vacLaunchConfigs {
  successEvents: string[];
  embedded?: boolean;
  hideHeader?: boolean;
  hideAvatar?: boolean;
  textInputPlaceHolder?: string;
  enableAutoOpenChatWidget?: boolean;
}

export interface renderVacProps {
  config: vacLaunchConfigs;
  virtualAgentProps: VirtualAgentControlProps;
  virtualAgentControlStore?: VirtualAgentControlStore;
}

export const embeddedVacProps = {
  embedded: true,
  hideHeader: true,
  hideAvatar: true,
  successEvents: VacConfig.successEventsForEmbedded,
  enableAutoOpenChatWidget: false,
};

export const floatingVacProps = {
  embedded: false,
  hideHeader: false,
  hideAvatar: false,
  successEvents: VacConfig.successEventsForFloating,
  enableAutoOpenChatWidget: false,
};
