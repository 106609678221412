import * as React from 'react';
import { UitkLoader } from 'uitk-react-loader';

export interface VacLoaderComponentProps {
  isLoading: boolean;
}
export const VacLoaderComponent: React.FC<VacLoaderComponentProps> = (props: VacLoaderComponentProps) => {
  return props.isLoading ? (
    <div className={'vacLoader'}>
      <UitkLoader />
    </div>
  ) : null;
};

VacLoaderComponent.displayName = 'VacLoaderComponent';
