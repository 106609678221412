const LoggingConstants = {
  PAGE_ID_NOT_FOUND: 'PAGE_ID_NOT_FOUND',
  INTENT_BUTTON_CLICK: 'INTENT_BUTTON_CLICK',
  SENDING_MESSAGE: 'SENDING_MESSAGE',
  SET_CONVERSATION_CONTEXT: 'SET_CONVERSATION_CONTEXT',
  VAC_NOT_INITIALISED: 'VAC_NOT_INITIALISED',
  VAC_SUCCESS_EVENT_RECEIVED: 'VAC_SUCCESS_EVENT_RECEIVED',
  VAC_ERROR_EVENT_RECEIVED: 'VAC_ERROR_EVENT_RECEIVED',
  VAC_EVENT_RECEIVED: 'VAC_EVENT_RECEIVED'
};

export default LoggingConstants;
