import { SystemEvent, SystemEventLevel } from '@shared-ui/logger-context';

export const EVENTS = {
  VAC_SUCCESS_EVENT_RECEIVED: new SystemEvent<{ message: string }>(
    SystemEventLevel.INFO,
    'VAC_SUCCESS_EVENT_RECEIVED_INFO'
  ),
  VAC_ERROR_EVENT_RECEIVED: new SystemEvent<{ message: string }>(
    SystemEventLevel.ERROR,
    'VAC_ERROR_EVENT_RECEIVED_ERROR'
  ),
  VAC_EVENT_RECEIVED: new SystemEvent<{ message: string }>(SystemEventLevel.INFO, 'VAC_EVENT_RECEIVED_INFO'),
  VAC_RELOADING: new SystemEvent<{ message: string }>(SystemEventLevel.INFO, 'VAC_RELOADING_INFO'),
  INTENT_BUTTON_CLICK: new SystemEvent<{ message: string }>(SystemEventLevel.INFO, 'INTENT_BUTTON_CLICK_INFO'),
  PAGE_ID_NOT_FOUND: new SystemEvent<{ message: string }>(SystemEventLevel.WARN, 'PAGE_ID_NOT_FOUND_WARN'),
  BANNER_AND_INTENT_BUTTONS_QUERY_ERROR: new SystemEvent<{ message: string }>(
    SystemEventLevel.ERROR,
    'BANNER_AND_INTENT_BUTTONS_QUERY_ERROR'
  ),
  UI_MESSAGE: new SystemEvent<{ message: string }>(SystemEventLevel.INFO, 'UI_MESSAGE'),
  CONTACT_US_BUTTON_CLICK: new SystemEvent<{ message: string }>(SystemEventLevel.INFO, 'CONTACT_US_BUTTON_CLICK'),
  OLD_CSP_ARTICLE: new SystemEvent<{ message: string }>(SystemEventLevel.INFO, 'OLD_CSP_ARTICLE'),
  NEW_CSP_ARTICLE: new SystemEvent<{ message: string }>(SystemEventLevel.INFO, 'NEW_CSP_ARTICLE'),
  PERSONALIZATION_INTENT_CLICK: new SystemEvent<{ message: string }>(SystemEventLevel.INFO, 'PERSONALIZATION_INTENT_CLICK'),
  PERSONALIZATION_BOOKING_CLICK: new SystemEvent<{ message: string }>(SystemEventLevel.INFO, 'PERSONALIZATION_BOOKING_CLICK')
};
